<template>
  <div class="text-left">
    <BaseHeader :title="'System Users'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>

      <!-- <div class=" card d-flex align-items-center flex-row w-100 justify-content-between  p-3  text-left my-1" style>
          <h2 class="card-title w-50 font-weight-black text-capitalize">
          Clients
        </h2>
          <v-text-field
          v-model="$store.state.search"
            dense
            outlined
            label="Filter clients"
            name="url"
            type="text"
            hide-details
          />
        </div> -->
      
      <div class=" mt-2 border-0 ">
        <div class="text-right p-3 card d-flex align-items-center flex-row w-100 justify-content-between " style>
          
          <v-text-field
          v-model="$store.state.search"
            dense
            outlined
            label="Filter System Users"
            name="url"
            type="text"
            hide-details
          />
          <!-- <v-spacer></v-spacer> -->
          <div class="w-50 ">
            <button
            class="my-auto float-right btn-add ml-2"
            @click.stop="
              $store.state.dialog = true;
            "
            >Add System Users</button>
            <!-- <button
            class="my-auto float-right btn-add px-3 ml-2"
            size="small"
            @click.stop="$store.state.dialog = true"
            >New</button
          > -->
          </div>
          
        </div>
        <!-- datatable -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="headers"
          :items="clients"
          :search="$store.state.search"
        >
          <template v-slot:[`item.deleted`]="{ item }">
            <v-chip v-if="item.status == '1'" outlined color="primary" small
              >Active</v-chip
            >
            <v-chip v-else color="error" small>Inactive</v-chip>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <router-link :to="{ name: 'ViewSystemClient', params: { id: item.id || '' } }">{{item.id}}</router-link>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <router-link :to="{ name: 'ViewSystemClient', params: { id: item.id || '' } }">{{item.email}}</router-link>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              fab
              elevation="0"
              x-small
              :to="{ name: 'ViewSystemClient', params: { id: item.id || '' } }"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
            <!-- <v-btn color="error" class="white--text" fab x-small>
                <v-icon small>mdi-delete</v-icon>
              </v-btn> -->
          </template>
        </v-data-table>
        <!-- /datatable -->
      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">New client</v-card-title>
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill"
              ref="clientsForm"
              @submit.prevent="createClient"
            >
              <div class>
                <div class="text-left mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <input
                        required
                        v-model="clientsForm.first_name"
                        name="first_name"
                        type="text"
                        class="form-control"
                        placeholder="First name"
                      />
                      <div class="form-control-feedback">
                        <i class="icon-user-check text-muted"></i>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <input
                        required
                        v-model="clientsForm.last_name"
                        name="last_name"
                        type="text"
                        class="form-control"
                        placeholder="Second name"
                      />
                      <div class="form-control-feedback">
                        <i class="icon-user-check text-muted"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    form-group form-group-feedback form-group-feedback-right
                  "
                >
                  <input
                    required
                    v-model="clientsForm.email"
                    name="email"
                    type="email"
                    class="form-control"
                    placeholder="Email Address"
                  />
                  <div class="form-control-feedback">
                    <i class="icon-mention text-muted"></i>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-4">
                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <vue-select
                        label="name"
                        required
                        v-model="clientsForm.country"
                        placeholder="Country: Start typing to search"
                        :options="countries"
                        :reduce="(country) => country.name"
                      ></vue-select>
                    </div>
                  </div>
                  <div class="col-sm-8">
                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <input
                        required
                        v-model="clientsForm.phone"
                        name="phone"
                        type="tel"
                        class="form-control"
                        placeholder="Your Phone"
                      />
                      <div class="form-control-feedback">
                        <i class="icon-phone text-muted"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <vue-select
                        label="name"
                        required
                        v-model="clientsForm.site_id"
                        placeholder="Site: Start typing to search"
                        :options="sites"
                        :reduce="(site) => site.id"
                      ></vue-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <input
                        required
                        v-model="clientsForm.password"
                        name="password"
                        type="password"
                        class="form-control"
                        placeholder="Create password"
                      />
                      <div class="form-control-feedback">
                        <i class="icon-user-lock text-muted"></i>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <input
                        required
                        v-model="clientsForm.password_confirmation"
                        name="password_confirmation"
                        type="password"
                        class="form-control"
                        placeholder="Repeat password"
                      />
                      <div class="form-control-feedback">
                        <i class="icon-user-lock text-muted"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  :disabled="clientsForm.busy"
                  type="submit"
                  class="btn bg-teal-400 btn-labeled btn-labeled-right"
                >
                  <b>
                    <i class="icon-plus3"></i>
                  </b>
                  Create Client
                </button>
              </div>
            </form>
            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Clients",
  computed: {
    ...mapState("clients", ["clientsForm", "loading", "clients", "headers"]),
    ...mapState("sites", ["sites"]),
  },
  data() {
    return {
      countries: [],
    };
  },

  methods: {
    ...mapActions("sites", ["getSites"]),
    ...mapActions("clients", ["getClients"]),
    createClient() {
      this.clientsForm
        .post(`${this.$baseUrl}/Sites/Clients`)
        .then((res) => {
          this.getClients();
          this.$store.state.dialog = false;
          this.$notify({
            title: "Success",
            text: "Client created successifully.",
            style: "success",
          });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            text: err.response.data
              ? err.response.data.Message
              : "Error while creating client",
            style: "danger",
          });
        });
    },
  },
  async mounted() {
    await this.getClients();
    await this.getSites();
  },
};
</script>